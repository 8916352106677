.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 110, 184, 0.849);
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1000;
  }
  
  .cookie-message span {
    margin-right: 10px;
  }
  
  .cookie-message button {
    background-color: #5ba332;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .cookie-consent {
        padding-bottom: 60px;
      }
  }